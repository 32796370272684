import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { white } from '../../design-system/variables/colors';
import { spaceBase, spaceLarge } from '../../design-system/variables/spaces';
import { md } from '../../design-system/media-queries';
import { Icon } from '../Icon';

const GlobalStyle = createGlobalStyle`
 .responsive-footer__social-icons-container {
	    width: 100%;
		max-width: 217px;
		margin: 0 auto ${spaceLarge} auto;

		${md`
			margin-bottom: ${spaceBase};
		`}

		.row {
			flex-wrap: nowrap;
		}
	}

 .responsive-footer__social-icons {
		padding: 0;
 }

	.responsive-footer__social-icon {
		display: flex;
    	justify-content: center;
		fill: ${white};
		line-height: 0;
	}

.x-icon {
	height: 24px;
}
`;

export const SocialMedia: React.FC = () => (
	<div className='col-xs-12'>
		<GlobalStyle />
		<div className='responsive-footer__social-icons-container'>
			<div className='row center-xs'>
				<div className='col-xs responsive-footer__social-icons'>
					<a
						className='responsive-footer__social-icon'
						href='https://www.instagram.com/inspirato/'
						target='_blank'
						rel='noopener noreferrer'
					>
						<Icon iconName='instagram' iconSize='md' />
					</a>
				</div>
				<div className='col-xs responsive-footer__social-icons'>
					<a
						className='responsive-footer__social-icon'
						href='https://www.facebook.com/Inspirato/'
						target='_blank'
						rel='noopener noreferrer'
					>
						<Icon iconName='facebook' iconSize='md' />
					</a>
				</div>
				<div className='col-xs responsive-footer__social-icons'>
					<a
						className='x-icon responsive-footer__social-icon'
						href='https://www.x.com/Inspirato'
						target='_blank'
						rel='noopener noreferrer'
					>
						<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
							<path
								className='ds-shape-primary'
								d="M13.9027 10.4351L21.3482 1.78033H19.5838L13.119 9.29512L7.95547 1.78033H2L9.8082 13.144L2 22.2198H3.76443L10.5915 14.284L16.0445 22.2198H22L13.9027 10.4351ZM4.40018 3.10857H7.11025L19.5847 20.952H16.8746L4.40018 3.10857Z"
							/>
						</svg>
					</a>
				</div>
				<div className='col-xs responsive-footer__social-icons'>
					<a
						className='responsive-footer__social-icon'
						href='https://www.youtube.com/user/Inspiratodotcom'
						target='_blank'
						rel='noopener noreferrer'
					>
						<Icon iconName='youtube' iconSize='md' />
					</a>
				</div>
			</div>
		</div>
	</div>
);
